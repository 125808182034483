nav ul {
	display: flex;
	list-style-type: none;
	margin: 0;
	width: 100%;
	margin-bottom: 0px;
	padding: 0;
}

.NavList li {
	text-align: center;
	width: 100%;
	display: block;
	padding: 0.8%;
	background-color: #dddddd;
}
