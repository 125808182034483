.Wrestling {
	background-image: linear-gradient(Black, Gray, Purple);
	height: 100%;
	width: 100%;
}
.roman {
	height: auto;
	width: 30%;
	transform: rotate(30deg);
	margin-top: 150px;
	margin-bottom: 0px;
	margin-right: 10%;
	padding-bottom: 10%;
	float: right;
}
.john {
	height: auto;
	width: 30%;
	margin-top: 10%;
	margin-left: 10%;
	padding-bottom: 10%;
	float: left;
	transform: rotate(-30deg);
}
.video {
	text-align: center;
	display: block;
	width: 75%;
	height: auto;
	margin: 0 auto;
	padding-bottom: 10%;
}
