.testimonial-bg-color {
	padding: 0px;
	margin: 0px;
	background-image: linear-gradient(Black, Gray, Purple);
	height: 100%;
}
.Test {
	text-align: center;
	margin-top: 0px;
	padding-top: 40px;
	margin-left: 0px;
	margin-right: 0px;
}
.Test li {
	display: block;
	font-size: 20px;
	font-family: Verdana;
	font-style: italic;
	margin-bottom: 25px;
	padding-bottom: 5%;
	color: white;
}
.Test li p {
	font-style: normal;
	display: inline;
}
