.Header {
	text-align: center;
	font-family: "Brush Script MT", cursive;
	height: 20%;
	width: 100%;
	background-color: aqua;
}
.Header p {
	padding: 0;
	margin: 0;
	font-size: 500%;
	background-image: linear-gradient(Black, Gray, Purple);
	color: transparent;
	background-clip: text;
}
