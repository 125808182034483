.about-bg-color {
	padding: 0%;
	padding-bottom: 10%;
	margin-right: 0%;
	background-image: linear-gradient(Black, Gray, Purple);
	height: 100%;
}

.about-box {
	width: 75%;
	border-radius: 20px;
	text-align: center;
	background-color: gray;

	padding-top: 10%;
	padding-bottom: 20%;
	margin: auto;
}

.about-header {
	text-align: center;
	text-decoration: underline;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-top: 0px;
	color: white;
	font-size: 200%;
	font-family: Arial, Helvetica, sans-serif;
}

.about-body {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 30px;
	margin: auto;
	font-size: 175%;
	font-weight: bold;
	font-family: "Brush Script MT", cursive;
	color: black;
}
