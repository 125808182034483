.songs-bg-color {
	padding: 0px;
	padding-bottom: 10%;
	margin: 0px;
	background-image: linear-gradient(Black, Gray, Purple);
	height: 100%;
}
.songs-box {
	max-width: 85%;
	height: auto;
	border-radius: 20px;
	text-align: center;
	background-color: gray;

	padding-top: 10%;

	padding-bottom: 10%;
	margin: auto;
	margin-top: 5%;
	margin-bottom: 5%;
}
.songs-favorites {
	text-align: center;
	text-decoration: underline;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-top: 0px;
	color: white;
	font-size: 42px;
	font-family: Arial, Helvetica, sans-serif;
}
iframe {
	display: inline-block;
	padding-right: 1%;
	padding-top: 1%;
	padding-left: 1%;
	padding-bottom: 1%;
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}
