.home-bg-color {
	padding: 0px;
	padding-bottom: 10px;
	margin: 0px;
	background-image: linear-gradient(Black, Gray, Purple);
	height: 100%;
	text-align: center;
}

.home-korean {
	padding-top: 70vh;
	padding-bottom: 10%;
	padding-left: 10%;
	padding-right: 10%;
	margin-top: 0px;
	color: white;
	font-size: 40px;
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
#heart {
	position: absolute;
	top: 55%;
	left: 15%;
	width: 300px;
	height: 270px;
}
#heart::before,
#heart::after {
	position: absolute;
	content: "";
	left: 150px;
	top: 0;
	width: 150px;
	height: 240px;
	background: red;
	border-radius: 150px 150px 0 0;
	transform: rotate(-45deg);
	transform-origin: 0 100%;
	animation: animate 1.3s ease-in infinite;
	box-shadow: 0px 0px 50px 10px red;
}
@keyframes animate {
	0% {
		box-shadow: 0px 0px 50px 10px red;
	}
	50% {
		box-shadow: 0px 0px 100px 10px red;
	}
	100% {
		box-shadow: 0px 0px 50px 10px red;
	}
}
#heart::after {
	left: 0;
	transform: rotate(45deg);
	transform-origin: 100% 100%;
}
